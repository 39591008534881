import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getShareComparisonList, getUserList } from '@/api/updateUserInfo/structure';
import { getStructureListArr } from '@/api/PublicAPI';
import { agentFlowerNameList, getCustomerLevel } from '@/api/ceshi_xiugai/whole';
import studentLevel from '@/components/studentLevel/index';
import MyExportExcel from '@/components/MyExportExcel/common';
export default {
  name: 'sharedCustomers',
  components: {
    Head: Head,
    studentLevel: studentLevel,
    MyExportExcel: MyExportExcel
  },
  data: function data() {
    var _ref;
    return _ref = {
      tab: 1,
      title: '共享客户数据',
      activeName: 'share',
      seeJudge: false,
      searchJudge: false,
      seeJudge1: false,
      searchJudge1: false,
      type: 1,
      page: 1,
      pagesize: 10,
      buried: [],
      buried2: [],
      is_director: this.$store.state.user.is_director,
      //是否是主管
      shareShow: true,
      receiveShow: true,
      key1: 0,
      key2: 0,
      key3: 0,
      key4: 0,
      parameter: {
        auth_rule_id: ''
      },
      shareName: '已共享客户数据',
      receiveName: '已接收客户数据',
      bumenoptionsName: '',
      //原始接收人所属部门
      bumenoptionsNames: '',
      //原始接收人所属部门
      bumenoptionsId: '',
      //原始接收人所属部门
      follow_Name: '',
      //创建人所属部门1
      follow_Names: '',
      //创建人所属部门2
      follow_Id: '',
      screen: {
        //共享
        sourceClientShow: false,
        //学员来源
        founderShow: false,
        //共享人
        founderMentShow: false,
        //创建人所属部门
        ReceivertShow: false,
        //原始数据接收人
        ReceiverMentShow: false,
        //原始接收人所属部门
        finalFollowshow: true,
        //创建日期
        Sharingshow: false,
        //共享日期
        studLevelShow: false,
        //现学员级别
        dataBelongShow: false,
        //数据归属
        nowDepartShow: false,
        //现跟进人所属部门
        nowPersonShow: false //现跟进人
      },
      share: {
        //共享
        tableData: [],
        //列表
        listLoading: true,
        //加载中
        ClassSearch: '',
        //搜索
        total: 0,
        //分页总数
        source_client_id: '',
        //学员来源默认值、数组
        flower_Array: [],
        follow_Id: '',
        //共享人默认值、数组
        follow_Id2: '',
        follow_Arr: [],
        follow_Arr2: [],
        follow_Ment: [],
        //创建人所属部门
        follow_Ments: [],
        //创建人所属部门
        Receiver: '',
        //原始数据接收人
        Receiver_Arr: [],
        bumenoptions: [],
        //原始接收人所属部门
        bumenoptionss: [],
        //原始接收人所属部门
        finalFollow: [],
        //创建日期
        start_time: '',
        end_time: '',
        union_time: '',
        SharingDate: '',
        //共享日期
        start_data: '',
        end_data: '',
        union_data: '',
        sort_time: '',
        //排序字段
        order_type: '',
        // lq 数据归属内容
        dataBelong: [{
          id: 1,
          belongName: '公海'
        }, {
          id: 0,
          belongName: '私海'
        }, {
          id: 2,
          belongName: '二级公海'
        }, {
          id: 3,
          belongName: '外送客户公海'
        }],
        sea_type: '',
        customer_level: '',
        nowfollowid: '',
        nowfollowidUser: '',
        nowfollowidUserNew: '',
        now_structure_id: ''
      },
      GetFieldInfodata2: [],
      // lq 结束
      courseName: 'introduction',
      coursekanban: 'director',
      tableData1: [],
      course: this.$route.query.course,
      prohibit: false,
      total: 0
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "page", 1), "pagesize", 10), "pageOperations", false), "IDesv", ''), "IDesarr", []), "comCreateshow", false), "comPlatshow", false), "comNameshow", false), "comWebsiteshow", false), "comCreateshow2", false), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "comPlatshow2", false), "comNameshow2", false), "comWebsiteshow2", false), "comCreate", ''), "comPlat", ''), "comName", ''), "comWebsite", ''), "operatorShow", false), "userNameshow", false), "typeShow", false), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "operationDataShow", false), "BelongingShow", false), "finalFollow", []), "start_follow_time", ''), "end_follow_time", ''), "structure_id", ''), "bumenoptions", []), "Buried", {
      search_username: '',
      search_realname: '',
      auth_rule_type: '1',
      search_create_time: '',
      search_structure_ids: '',
      search_auth_rule_title: ''
    }), "BuriedData1", []), "operatorShow", false), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "userNameshow", false), "typeShow", false), "operationDataShow", false), "BelongingShow", false), "jurisdictionBtn", []);
  },
  mounted: function mounted() {
    var _this2 = this;
    var buriedES = this.$store.getters.JsonArr1;
    if (this.course != 1) {
      var jurisdiction = this.$store.getters.Atter;
      if (buriedES != undefined) {
        buriedES.forEach(function (item) {
          if (item.title == '已共享客户数据') {
            _this2.buried = item.children;
          } else if (item.title == '已接收客户数据') {
            _this2.buried2 = item.children;
          }
        });
      }
      if (jurisdiction.indexOf('已共享客户数据') != -1 && jurisdiction.indexOf('已接收客户数据') != -1) {
        this.type = 1;
        this.activeName = 'share';
        this.SharedTime();
      } else {
        document.getElementsByClassName('el-tabs__header')[0].style.display = 'none';
        if (jurisdiction.indexOf('已共享客户数据') != -1) {
          this.type = 1;
          this.activeName = 'share';
        } else {
          this.type = 2;
          this.activeName = 'receive';
        }
        this.Accepted();
      }
      if (jurisdiction.length == 1) {
        if (jurisdiction[0] == '已共享客户数据') {
          this.activeName = 'share';
        } else {
          this.activeName = 'receive';
        }
      }
      this.showHiddin();
      this.buriedPoint('查看');
      this.getList(this.type, this.page, this.pagesize);
      this.defaultChange();
    } else {}
    // lq 新增
    this.PostMultipleFocus();
  },
  computed: {
    sumTimeFilter: function sumTimeFilter() {
      return function (val) {
        if (val == 0) {
          return '-';
        }
        if (!val) {
          return '';
        }
        var absVal = Math.abs(val);
        var str = '';
        if (Number(absVal) >= 24 * 60 * 60) {
          var day = parseInt(Number(absVal) / (24 * 60 * 60));
          var yu = Number(absVal) % (24 * 60 * 60);
          var hour = parseInt(yu / (60 * 60));
          var min = parseInt(yu % (60 * 60) / 60);
          var sec = parseInt(yu % (60 * 60) % 60);
          str = "".concat(day, "\u5929").concat(hour, "\u65F6").concat(min, "\u5206").concat(sec, "\u79D2");
        } else if (Number(absVal) >= 60 * 60) {
          var _hour = parseInt(Number(absVal) / (60 * 60));
          var _min = parseInt(Number(absVal) % (60 * 60) / 60);
          var _sec = parseInt(Number(absVal) % (60 * 60) % 60);
          str = "".concat(_hour, "\u65F6").concat(_min, "\u5206").concat(_sec, "\u79D2");
        } else if (Number(absVal) >= 60) {
          var _min2 = parseInt(Number(absVal) / 60);
          var _sec2 = parseInt(Number(absVal) % 60);
          str = "".concat(_min2, "\u5206").concat(_sec2, "\u79D2");
        } else if (Number(absVal) < 60) {
          str = "".concat(Number(absVal), "\u79D2");
        }
        if (Number(val) < 0) {
          return "-".concat(str);
        }
        return "".concat(str);
      };
    }
  },
  methods: {
    downLoadExcelFun: function downLoadExcelFun() {
      var exportExcel = _objectSpread(_objectSpread({}, this.parameter), {}, {
        is_export: 1
      });
      var url = 'ShareComparison/getShareComparisonList';
      var title = '';
      if (this.activeName == 'share') {
        title = '已共享客户数据';
      } else {
        title = '已接收客户数据';
      }
      this.$refs.downloadExcelRef.propDataFun(url, exportExcel, title);
    },
    SharedTime: function SharedTime() {
      //当前设定的日期时间
      var arr = [];
      var d = new Date();
      var year1, month1, day1;
      var _ref2 = [d.getFullYear(), d.getMonth(), d.getDate()];
      year1 = _ref2[0];
      month1 = _ref2[1];
      day1 = _ref2[2];
      var date1 = new Date(year1, month1, day1, 23, 59, 59);
      this.share.finalFollow.push(date1);
      //前一天设定的日期时间
      var year2, month2, day2;
      d.setTime(d.getTime() - 24 * 60 * 60 * 1000);
      var _ref3 = [d.getFullYear(), d.getMonth(), d.getDate()];
      year2 = _ref3[0];
      month2 = _ref3[1];
      day2 = _ref3[2];
      var date2 = new Date(year2, month2, 0, 24);
      this.share.finalFollow.unshift(date2);
      var start_time = Date.parse(this.share.finalFollow[0]).toString().substring(0, 10);
      var end_time = Date.parse(this.share.finalFollow[1]).toString().substring(0, 10);
      this.union_time = start_time + ',' + end_time;
    },
    Accepted: function Accepted() {
      //当前设定的日期时间
      var arr = [];
      var d = new Date();
      var year1, month1, day1;
      var _ref4 = [d.getFullYear(), d.getMonth(), d.getDate()];
      year1 = _ref4[0];
      month1 = _ref4[1];
      day1 = _ref4[2];
      var date1 = new Date(year1, month1, day1, 23, 59, 59);
      this.share.finalFollow.push(date1);
      //前一天设定的日期时间
      var year2, month2, day2;
      d.setTime(d.getTime() - 24 * 60 * 60 * 1000);
      var _ref5 = [d.getFullYear(), d.getMonth(), d.getDate()];
      year2 = _ref5[0];
      month2 = _ref5[1];
      day2 = _ref5[2];
      var date2 = new Date(year2, month2, 0, 24);
      this.share.finalFollow.unshift(date2);
      this.share.start_time = Date.parse(this.share.finalFollow[0]).toString().substring(0, 10);
      this.share.end_time = Date.parse(this.share.finalFollow[1]).toString().substring(0, 10);
      this.union_time = this.share.start_time + ',' + this.share.end_time;
    },
    showHiddin: function showHiddin() {
      var _this3 = this;
      this.jurisdictionBtn = [];
      if (this.activeName == 'share') {
        var JsonArr1 = this.$store.getters.JsonArr1[0].children;
        if (JsonArr1 != undefined) {
          JsonArr1.forEach(function (item) {
            _this3.jurisdictionBtn.push(item.title);
          });
          if (this.jurisdictionBtn.indexOf('查看') != -1) {
            this.seeJudge = true;
            this.seeJudge1 = true;
          }
          if (this.jurisdictionBtn.indexOf('搜索') != -1) {
            this.searchJudge = true;
            this.searchJudge1 = true;
          }
        }
      } else {
        var _JsonArr;
        if (this.$store.getters.JsonArr1.length > 1) {
          _JsonArr = this.$store.getters.JsonArr1[1].children;
        } else {
          _JsonArr = this.$store.getters.JsonArr1[0].children;
        }
        if (_JsonArr != undefined) {
          _JsonArr.forEach(function (item) {
            _this3.jurisdictionBtn.push(item.title);
          });
          if (this.jurisdictionBtn.indexOf('查看') != -1) {
            this.seeJudge1 = true;
            this.seeJudge = true;
          }
          if (this.jurisdictionBtn.indexOf('搜索') != -1) {
            this.searchJudge1 = true;
            this.searchJudge = true;
          }
        }
      }
    },
    statisticalTable: function statisticalTable(page, pagesize) {
      var _this4 = this;
      this.Buried.page = page;
      this.Buried.pagesize = pagesize;
      this.Buried.auth_rule_pid = this.menuId;
      OperatorDataIndex(this.Buried).then(function (res) {
        _this4.BuriedData1 = res.data.data;
        _this4.total = res.data.total;
      });
    },
    courseClick: function courseClick() {
      var _this5 = this;
      if (this.courseName = 'statistics') {
        getStructureListArr().then(function (respomse) {
          _this5.bumenoptions = respomse.data;
        });
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    StatisticsClick: function StatisticsClick() {
      this.operatorShow = false;
      this.userNameshow = false;
      this.typeShow = false;
      this.operationDataShow = false;
      this.BelongingShow = false;
      this.finalFollow = '';
      this.Buried = {
        search_username: '',
        search_realname: '',
        search_create_time: '',
        search_structure_ids: '',
        search_auth_rule_title: ''
      };
      if (this.coursekanban == 'director') {
        this.Buried.auth_rule_type = '1';
      } else {
        this.Buried.auth_rule_type = '2';
      }
      this.statisticalTable(this.page, this.pagesize);
    },
    operatorClick: function operatorClick() {
      //操作人
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    userNamesClick: function userNamesClick() {
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.page = 1;
      this.statisticalTable(this.page, this.pagesize);
    },
    FinalFollowUpEv: function FinalFollowUpEv() {
      this.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.Buried.search_create_time = this.start_follow_time + ',' + this.end_follow_time;
        this.statisticalTable(this.page, this.pagesize);
      }
    },
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      this.page = 1;
      this.Buried.search_structure_ids = adad;
      this.statisticalTable(this.page, this.pagesize);
    },
    // 系统教程、统计
    buriedPoint: function buriedPoint(name) {
      var _this6 = this;
      if (this.activeName == 'share') {
        this.buried.forEach(function (item) {
          if (item.title == name) {
            _this6.parameter.auth_rule_id = item.id;
          }
        });
        return this.parameter.auth_rule_id;
      } else {
        this.buried2.forEach(function (item) {
          if (item.title == name) {
            _this6.parameter.auth_rule_id = item.id;
          }
        });
        return this.parameter.auth_rule_id;
      }
    },
    handleClick: function handleClick(tab, event) {
      this.showHiddin();
      if (tab.name == 'share') {
        this.type = 1;
      } else {
        this.type = 2;
      }
      this.buriedPoint('查看');
      this.key1++;
      this.key2++;
      this.key3++;
      this.key4++;
      this.bumenoptionsName = ''; //原始接收人所属部门
      this.bumenoptionsId = ''; //原始接收人所属部门
      this.follow_Name = ''; //创建人所属部门
      this.follow_Names = ''; //创建人所属部门
      this.follow_Id = ''; //创建人所属部门
      this.share = {
        tableData: [],
        //列表
        listLoading: true,
        //加载中
        ClassSearch: '',
        //搜索
        total: 0,
        //分页总数
        source_client_id: '',
        //学员来源默认值、数组
        flower_Array: [],
        follow_Id: '',
        //共享人默认值、数组
        follow_Id2: '',
        follow_Arr: [],
        follow_Arr2: [],
        follow_Ment: [],
        //创建人所属部门
        follow_Ments: [],
        //创建人所属部门
        Receiver: '',
        //原始数据接收人
        Receiver_Arr: [],
        bumenoptions: [],
        //原始接收人所属部门
        bumenoptionss: [],
        //原始接收人所属部门
        finalFollow: [],
        //创建日期
        start_time: '',
        end_time: '',
        union_time: '',
        SharingDate: '',
        //共享日期
        start_data: '',
        end_data: '',
        union_data: '',
        sort_time: '',
        //排序字段
        order_type: '',
        // lq 数据归属内容
        dataBelong: [{
          id: 1,
          belongName: '公海'
        }, {
          id: 0,
          belongName: '私海'
        }, {
          id: 2,
          belongName: '二级公海'
        }, {
          id: 3,
          belongName: '外送客户公海'
        }],
        sea_type: '',
        customer_level: '',
        nowfollowid: '',
        nowfollowidUser: '',
        nowfollowidUserNew: '',
        now_structure_id: ''
      };
      this.screen = {
        sourceClientShow: false,
        //学员来源
        founderShow: false,
        //共享人
        founderMentShow: false,
        //创建人所属部门
        ReceivertShow: false,
        //原始数据接收人
        ReceiverMentShow: false,
        //原始接收人所属部门
        finalFollowshow: true,
        //创建日期
        Sharingshow: false,
        //共享日期
        studLevelShow: false,
        //现学员级别
        dataBelongShow: false,
        //数据归属
        nowDepartShow: false,
        //现跟进人所属部门
        nowPersonShow: false //现跟进人
      };
      if (tab.name == 'share') {
        this.SharedTime();
      } else {
        this.Accepted();
      }
      this.defaultChange();
      this.getList(this.type, this.page, this.pagesize);
    },
    defaultChange: function defaultChange() {
      var _this = this.share;
      agentFlowerNameList().then(function (res) {
        _this.flower_Array = res.data;
      });
      if (this.type == 1) {
        getUserList({
          get_type: '6',
          type: '0'
        }).then(function (res) {
          //共享人
          _this.follow_Arr2 = res.data;
        });
        getUserList({
          type: '1',
          get_type: '6'
        }).then(function (res) {
          //创建人所属部门
          res.data.forEach(function (element) {
            element.user_arr.map(function (item) {
              item.name = item.realname;
            });
          });
          _this.follow_Ment = res.data;
        });
        getUserList({
          get_type: '1',
          type: '0'
        }).then(function (res) {
          //原始数据接收人
          _this.Receiver_Arr = res.data;
        });
        getStructureListArr({
          type: '1',
          get_type: '0'
        }).then(function (res) {
          //原始接收人所属部门
          _this.bumenoptions = res.data;
        });
      } else {
        getUserList({
          get_type: '1',
          type: '0'
        }).then(function (res) {
          //共享人
          _this.follow_Arr2 = res.data;
        });
        getUserList({
          type: '1',
          get_type: '6'
        }).then(function (res) {
          //原始接收人所属部门
          res.data.forEach(function (element) {
            element.user_arr.map(function (item) {
              item.name = item.realname;
            });
          });
          _this.bumenoptionss = res.data;
        });
        getUserList({
          get_type: '6',
          type: '0'
        }).then(function (res) {
          //共享人
          _this.Receiver_Arr = res.data;
        });
        getStructureListArr({
          type: '1',
          get_type: '6'
        }).then(function (res) {
          //创建人所属部门
          _this.follow_Ments = res.data;
        });
      }
    },
    getList: function getList(type, page, pagesize) {
      var _this7 = this;
      var _this = this.share;
      _this.listLoading = false;
      if (_this.sort_time != '' && _this.order_type != '') {
        this.parameter = {
          type: String(type),
          page: String(page),
          auth_rule_id: this.parameter.auth_rule_id,
          pagesize: String(pagesize),
          search_source_client_name: String(_this.source_client_id),
          search_share: String(_this.follow_Id),
          search_create_structure: String(this.follow_Id),
          search_receive: String(_this.Receiver),
          search_receive_structure: String(this.bumenoptionsId),
          search_create_time: this.union_time,
          search_share_time: _this.union_data,
          order_field: _this.sort_time,
          order_type: _this.order_type,
          search_keyword: String(_this.ClassSearch),
          search_create: String(_this.follow_Id2),
          search_platform_name: this.comPlat,
          search_project_name: this.comName,
          search_communication_website: this.comWebsite
        };
      } else {
        this.parameter = {
          type: String(type),
          page: String(page),
          auth_rule_id: this.parameter.auth_rule_id,
          pagesize: String(pagesize),
          search_source_client_name: String(_this.source_client_id),
          search_share: String(_this.follow_Id),
          search_create_structure: String(this.follow_Id),
          search_receive: String(_this.Receiver),
          search_receive_structure: String(this.bumenoptionsId),
          search_create_time: this.union_time,
          search_share_time: _this.union_data,
          search_keyword: String(_this.ClassSearch),
          search_create: String(_this.follow_Id2),
          search_platform_name: this.comPlat,
          search_project_name: this.comName,
          search_communication_website: this.comWebsite
        };
      }
      var paramsNew = {
        // lq 新增
        customer_level: _this.customer_level,
        sea_type: _this.sea_type,
        nowfollowid: _this.nowfollowid,
        now_structure_id: _this.now_structure_id
      };
      if (this.activeName == 'receive') {
        paramsNew.nowfollowid = _toConsumableArray(new Set(_this.nowfollowid.split(','))).toString();
      }
      this.parameter = _objectSpread(_objectSpread({}, paramsNew), this.parameter);
      getShareComparisonList(this.deleteEmptyProperty(this.parameter)).then(function (res) {
        _this7.share.tableData = res.data.data;
        _this7.share.total = res.data.total;
        _this7.share.listLoading = false;
      });
    },
    //  共享的筛选事件
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.pagesize = val;
      if (this.course != 1) {
        this.getList(this.type, this.page, this.pagesize);
      } else {
        this.statisticalTable(this.page, val);
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.page = val;
      if (this.course != 1) {
        this.getList(this.type, this.page, this.pagesize);
      } else {
        this.statisticalTable(val, this.pagesize);
      }
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        if (column.order == 'ascending') {
          this.share.order_type = 'asc';
        } else if (column.order == 'descending') {
          this.share.order_type = 'desc';
        } else {
          this.share.order_type = '';
        }
        this.share.sort_time = 'create_time';
      } else if (column.prop == 'share_time') {
        if (column.order == 'ascending') {
          this.share.order_type = 'asc';
        } else if (column.order == 'descending') {
          this.share.order_type = 'desc';
        } else {
          this.share.order_type = '';
        }
        this.share.sort_time = 'share_time';
      }
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    ClassSearchInput: function ClassSearchInput() {
      //搜索
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    flowerArrayEs: function flowerArrayEs() {
      //学员来源筛选
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    followidEs: function followidEs(val) {
      //共享人改变值
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    SharerBlur: function SharerBlur() {
      var _this8 = this;
      //共享人点击时
      if (this.type == 1) {
        getUserList({
          get_type: '6',
          type: '0'
        }).then(function (res) {
          //共享人
          _this8.share.follow_Arr = res.data;
        });
      } else {
        getUserList({
          get_type: '1',
          type: '0'
        }).then(function (res) {
          //共享人
          _this8.share.follow_Arr = res.data;
        });
      }
    },
    SharerBlur2: function SharerBlur2() {
      var _this9 = this;
      //共享人点击时
      if (this.type == 1) {
        getUserList({
          get_type: '6',
          type: '0'
        }).then(function (res) {
          //共享人
          _this9.share.follow_Arr2 = res.data;
        });
      } else {
        getUserList({
          get_type: '1',
          type: '0'
        }).then(function (res) {
          //共享人
          _this9.share.follow_Arr2 = res.data;
        });
      }
    },
    comNameClick: function comNameClick() {
      this.page = 1;
      this.getList(this.type, this.page, this.pagesize);
    },
    comCreateClick: function comCreateClick() {
      this.page = 1;
      this.getList(this.type, this.page, this.pagesize);
    },
    comPlatClick: function comPlatClick() {
      this.page = 1;
      this.getList(this.type, this.page, this.pagesize);
    },
    comWebsiteClick: function comWebsiteClick() {
      this.page = 1;
      this.getList(this.type, this.page, this.pagesize);
    },
    followMethod: function followMethod(val) {
      var _this10 = this;
      //共享人搜索
      if (this.type == 1) {
        if (val == '') {
          getUserList({
            get_type: '6',
            type: '0'
          }).then(function (res) {
            _this10.share.follow_Arr = res.data;
          });
        } else {
          getUserList({
            get_type: '6',
            type: '0',
            real_name: val
          }).then(function (res) {
            _this10.share.follow_Arr = res.data;
          });
        }
      } else {
        if (val == '') {
          getUserList({
            get_type: '1',
            type: '0'
          }).then(function (res) {
            _this10.share.follow_Arr = res.data;
          });
        } else {
          getUserList({
            get_type: '1',
            type: '0',
            real_name: val
          }).then(function (res) {
            _this10.share.follow_Arr = res.data;
          });
        }
      }
    },
    followMethod2: function followMethod2(val) {
      var _this11 = this;
      //创建人搜索
      if (this.type == 1) {
        if (val == '') {
          getUserList({
            get_type: '6',
            type: '0'
          }).then(function (res) {
            _this11.share.follow_Arr2 = res.data;
          });
        } else {
          getUserList({
            get_type: '6',
            type: '0',
            real_name: val
          }).then(function (res) {
            _this11.share.follow_Arr2 = res.data;
          });
        }
      } else {
        if (val == '') {
          getUserList({
            get_type: '1',
            type: '0'
          }).then(function (res) {
            _this11.share.follow_Arr2 = res.data;
          });
        } else {
          getUserList({
            get_type: '1',
            type: '0',
            real_name: val
          }).then(function (res) {
            _this11.share.follow_Arr2 = res.data;
          });
        }
      }
    },
    followMent: function followMent(val) {
      //创建人所属部门
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var aaaa = share == ',' ? '' : share;
      this.follow_Id = aaaa;
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    // lq 新增
    followMentNew: function followMentNew(val, type) {
      // type为了区分 现跟进人的下拉框 和现跟进人所属部门走一个事件
      if (type == '3') {
        var arrSelect = this.share.nowfollowid != '' ? this.share.nowfollowid.split(',') : [];
        if (this.share.nowfollowidUserNew == '') {
          this.share.nowfollowidUserNew = val;
        } else {
          if (arrSelect.indexOf(this.share.nowfollowidUserNew.toString()) != -1) {
            arrSelect.splice(arrSelect.indexOf(this.share.nowfollowidUserNew.toString()), 1);
          }
        }
        if (this.share.nowfollowidUser != '') {
          arrSelect.push(this.share.nowfollowidUser.toString());
        }
        this.share.nowfollowid = arrSelect.toString();
        this.share.nowfollowidUserNew = val;
      } else {
        var share = val.map(function (e) {
          return e[e.length - 1];
        }).toString(); // 部门的最后一位ID
        var aaaa = share == ',' ? '' : share;
        if (this.activeName == 'receive') {
          var arr1 = aaaa != '' ? aaaa.split(',') : [];
          if (this.share.nowfollowidUser == '') {
            this.share.nowfollowid = arr1.toString();
          } else {
            arr1.push(this.share.nowfollowidUser.toString());
            this.share.nowfollowid = arr1.toString();
          }
        } else {
          this.share.now_structure_id = aaaa;
        }
      }
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    ReceiverInput: function ReceiverInput(val) {
      //原始数据接收人
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    ReceiverBlur: function ReceiverBlur() {
      var _this12 = this;
      //数据接收人点击时
      if (this.type == 1) {
        getUserList({
          get_type: '1',
          type: '0'
        }).then(function (res) {
          //共享人
          _this12.share.Receiver_Arr = res.data;
        });
      } else {
        getUserList({
          get_type: '6',
          type: '0'
        }).then(function (res) {
          //共享人
          _this12.share.Receiver_Arr = res.data;
        });
      }
    },
    ReceiverMethod: function ReceiverMethod(val) {
      var _this13 = this;
      //数据接收人搜索
      if (this.type == 1) {
        if (val == '') {
          getUserList({
            get_type: '1',
            type: '0'
          }).then(function (res) {
            _this13.share.Receiver_Arr = res.data;
          });
        } else {
          getUserList({
            get_type: '1',
            type: '0',
            real_name: val
          }).then(function (res) {
            _this13.share.Receiver_Arr = res.data;
          });
        }
      } else {
        if (val == '') {
          getUserList({
            get_type: '6',
            type: '0'
          }).then(function (res) {
            _this13.share.Receiver_Arr = res.data;
          });
        } else {
          getUserList({
            get_type: '6',
            type: '0',
            real_name: val
          }).then(function (res) {
            _this13.share.Receiver_Arr = res.data;
          });
        }
      }
    },
    handleChange: function handleChange(val) {
      //原始接收人所属部门
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.bumenoptionsId = share == ',' ? '' : share;
      console.log(this.bumenoptionsId);
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //创建日期筛选
      var _this = this.share;
      if (_this.finalFollow == null) {
        _this.start_time = '';
        _this.end_time = '';
        this.union_time = '';
      } else {
        _this.start_time = _this.finalFollow[0].toString().substring(0, 10);
        _this.end_time = _this.finalFollow[1].toString().substring(0, 10);
        this.union_time = _this.start_time + ',' + _this.end_time;
      }
      this.page = 1;
      this.buriedPoint('搜索');
      console.log(this.union_time);
      this.getList(this.type, this.page, this.pagesize);
    },
    SharingDateClick: function SharingDateClick() {
      //共享日期筛选
      var _this = this.share;
      if (_this.SharingDate == null) {
        _this.start_data = '';
        _this.end_data = '';
        _this.union_data = '';
      } else {
        _this.start_data = _this.SharingDate[0].toString().substring(0, 10);
        _this.end_data = _this.SharingDate[1].toString().substring(0, 10);
        _this.union_data = _this.start_data + ',' + _this.end_data;
      }
      this.page = 1;
      this.buriedPoint('搜索');
      this.getList(this.type, this.page, this.pagesize);
    },
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      var _this = this.screen;
      if (item === '学员来源') {
        _this.sourceClientShow = true;
      } else if (item === '共享人') {
        _this.founderShow = true;
      } else if (item === '创建人所属部门') {
        _this.founderMentShow = true;
      } else if (item === '原始数据接收人') {
        _this.ReceivertShow = true;
      } else if (item === '原始接收人所属部门') {
        _this.ReceiverMentShow = true;
      } else if (item === '创建日期') {
        _this.finalFollowshow = true;
      } else if (item === '共享日期') {
        _this.Sharingshow = true;
      } else if (item === '操作人') {
        this.operatorShow = true;
      } else if (item === '用户名') {
        this.userNameshow = true;
      } else if (item === '操作日期') {
        this.operationDataShow = true;
      } else if (item === '操作人所属部门') {
        this.BelongingShow = true;
      } else if (item === '操作类型') {
        this.typeShow = true;
      } else if (item === '创建人') {
        this.comCreateshow = true;
      } else if (item === '推广平台') {
        this.comPlatshow = true;
      } else if (item === '推广项目') {
        this.comNameshow = true;
      } else if (item === '推广地址') {
        this.comWebsiteshow = true;
      } else if (item === '创建人 ') {
        this.comCreateshow2 = true;
      } else if (item === '推广平台 ') {
        this.comPlatshow2 = true;
      } else if (item === '推广项目 ') {
        this.comNameshow2 = true;
      } else if (item === '推广地址 ') {
        this.comWebsiteshow2 = true;
      } else if (item === '现学员级别') {
        // lq新增
        _this.studLevelShow = true;
      } else if (item === '数据归属') {
        // lq新增
        _this.dataBelongShow = true;
      } else if (item === '现跟进人所属部门') {
        _this.nowDepartShow = true;
      } else if (item === '现跟进人') {
        _this.nowPersonShow = true;
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.page = 1;
      if (item === '学员来源') {
        this.share.source_client_id = '';
        this.screen.sourceClientShow = false;
        this.buriedPoint('搜索');
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '共享人') {
        this.share.follow_Id = '';
        this.screen.founderShow = false;
        this.buriedPoint('搜索');
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '创建人所属部门') {
        this.follow_Name = '';
        this.follow_Names = '';
        this.follow_Id = '';
        this.screen.founderMentShow = false;
        this.buriedPoint('搜索');
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '原始数据接收人') {
        this.share.Receiver = '';
        this.screen.ReceivertShow = false;
        this.buriedPoint('搜索');
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '原始接收人所属部门') {
        this.bumenoptionsNames = '';
        this.bumenoptionsName = '';
        this.bumenoptionsId = '';
        this.screen.ReceiverMentShow = false;
        this.buriedPoint('搜索');
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '创建日期') {
        this.share.finalFollow = '';
        this.share.start_time = '';
        this.share.end_time = '';
        this.share.union_time = '';
        this.union_time = '';
        this.screen.finalFollowshow = false;
        this.buriedPoint('搜索');
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '共享日期') {
        this.share.SharingDate = '';
        this.share.start_data = '';
        this.share.end_data = '';
        this.share.union_data = '';
        this.screen.Sharingshow = false;
        this.buriedPoint('搜索');
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '操作人') {
        this.operatorShow = false;
        this.Buried.search_realname = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '用户名') {
        this.userNameshow = false;
        this.Buried.search_username = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作日期') {
        this.operationDataShow = false;
        this.finalFollow = '';
        this.Buried.search_create_time = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作人所属部门') {
        this.BelongingShow = false;
        this.structure_id = '';
        this.Buried.search_structure_ids = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '操作类型') {
        this.typeShow = false;
        this.Buried.search_auth_rule_title = '';
        this.statisticalTable(this.page, this.pagesize);
      } else if (item === '创建人') {
        this.comCreateshow = false;
        this.share.follow_Id2 = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '推广平台') {
        this.comPlatshow = false;
        this.comPlat = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '推广项目') {
        this.comNameshow = false;
        this.comName = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '推广地址') {
        this.comWebsiteshow = false;
        this.comWebsite = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '创建人 ') {
        this.comCreateshow2 = false;
        this.share.follow_Id2 = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '推广平台 ') {
        this.comPlatshow2 = false;
        this.comPlat = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '推广项目 ') {
        this.comNameshow2 = false;
        this.comName = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '推广地址 ') {
        this.comWebsiteshow2 = false;
        this.comWebsite = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '现学员级别') {
        // lq新增
        this.screen.studLevelShow = false;
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '数据归属') {
        // lq新增
        this.screen.dataBelongShow = false;
        this.share.sea_type = '';
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '现跟进人所属部门') {
        this.screen.nowDepartShow = false;
        // 两个tab需要清空的参数不同
        if (this.activeName == 'share') {
          this.share.now_structure_id = '';
          this.$refs.nowDepartRef1.$refs.panel.clearCheckedNodes();
        } else {
          this.share.nowfollowid = '';
          this.share.nowfollowidUser = '';
          this.$refs.nowDepartRef2.$refs.panel.clearCheckedNodes();
        }
        this.getList(this.type, this.page, this.pagesize);
      } else if (item === '现跟进人') {
        if (this.activeName == 'share') {
          this.share.nowfollowid = '';
        } else {
          // 第二个tab不能直接清空nowfollowid参数 因为部门和人放在一个参数里
          var arr = this.share.nowfollowid.split(',');
          var index = arr.indexOf(String(this.share.nowfollowidUser));
          if (index == -1) {
            this.share.nowfollowid = '';
          } else {
            arr.splice(index, 1);
            this.share.nowfollowid = arr.toString();
          }
          this.share.nowfollowidUser = '';
        }
        this.screen.nowPersonShow = false;
        this.getList(this.type, this.page, this.pagesize);
      }
    },
    // lq新增
    PostMultipleFocus: function PostMultipleFocus() {
      var _this14 = this;
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        _this14.GetFieldInfodata2 = res.data;
      });
    },
    studentLevel: function studentLevel(val) {
      // 现学员级别
      this.share.customer_level = val;
      this.page = 1;
      this.getList(this.type, this.page, this.pagesize);
    }
  }
};