var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container sharedCustomers" },
    [
      _vm.course != 1
        ? _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-header",
                { staticStyle: { height: "auto" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 24 } },
                    [_c("Head", { attrs: { name: _vm.title } })],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "relative",
                    width: "100%",
                    top: "56px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "80px",
                        top: "-42px",
                        "z-index": "100",
                      },
                      attrs: { size: "small", type: "warning" },
                      on: { click: _vm.downLoadExcelFun },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["已共享客户数据"],
                          expression: "['已共享客户数据']",
                        },
                      ],
                      attrs: { label: "已共享客户数据", name: "share" },
                    },
                    [
                      _vm.searchJudge
                        ? _c(
                            "el-row",
                            {
                              staticClass: "btn",
                              staticStyle: { "margin-right": "0" },
                              attrs: { gutter: 24 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    "padding-right": "0",
                                    "padding-top": "20px",
                                    float: "right",
                                  },
                                  attrs: { span: 4 },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "input-with-select",
                                      attrs: {
                                        placeholder: "请搜索电话、名称",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.share.ClassSearch,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.share,
                                            "ClassSearch",
                                            $$v
                                          )
                                        },
                                        expression: "share.ClassSearch",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          type: "primary",
                                          size: "small",
                                          icon: "el-icon-search",
                                        },
                                        on: { click: _vm.ClassSearchInput },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.searchJudge
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.sourceClientShow,
                                          expression: "screen.sourceClientShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("学员来源：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "0",
                                          },
                                          attrs: {
                                            size: "small",
                                            filterable: "",
                                            multiple: "",
                                            "collapse-tags": "",
                                            placeholder: "请搜索学员来源",
                                            clearable: "",
                                          },
                                          on: { change: _vm.flowerArrayEs },
                                          model: {
                                            value: _vm.share.source_client_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "source_client_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "share.source_client_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.flower_Array,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.flower_name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("学员来源")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.founderShow,
                                          expression: "screen.founderShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("共享人：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          ref: "clearValue",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.followMethod,
                                            placeholder: "请选择共享人",
                                          },
                                          on: {
                                            change: _vm.followidEs,
                                            blur: _vm.SharerBlur,
                                          },
                                          model: {
                                            value: _vm.share.follow_Id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "follow_Id",
                                                $$v
                                              )
                                            },
                                            expression: "share.follow_Id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.follow_Arr,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("共享人")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.founderMentShow,
                                          expression: "screen.founderMentShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("创建人所属部门：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        key: _vm.key1,
                                        attrs: {
                                          placeholder: "请选择部门",
                                          options: _vm.share.follow_Ment,
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                            children: "user_arr",
                                          },
                                          size: "small",
                                          "collapse-tags": "",
                                          clearable: "",
                                        },
                                        on: { change: _vm.followMent },
                                        model: {
                                          value: _vm.follow_Name,
                                          callback: function ($$v) {
                                            _vm.follow_Name = $$v
                                          },
                                          expression: "follow_Name",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "创建人所属部门"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.ReceivertShow,
                                          expression: "screen.ReceivertShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("原始数据接收人：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          ref: "clearValue",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.ReceiverMethod,
                                            placeholder: "请选择接收人",
                                          },
                                          on: {
                                            change: _vm.ReceiverInput,
                                            blur: _vm.ReceiverBlur,
                                          },
                                          model: {
                                            value: _vm.share.Receiver,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "Receiver",
                                                $$v
                                              )
                                            },
                                            expression: "share.Receiver",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.Receiver_Arr,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "原始数据接收人"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.ReceiverMentShow,
                                          expression: "screen.ReceiverMentShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("原始接收人所属部门：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        key: _vm.key2,
                                        attrs: {
                                          options: _vm.share.bumenoptions,
                                          clearable: "",
                                          filterable: "",
                                          size: "small",
                                          "expand-trigger": "hover",
                                          placeholder: "请选择部门",
                                          "collapse-tags": "",
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                          },
                                        },
                                        on: { change: _vm.handleChange },
                                        model: {
                                          value: _vm.bumenoptionsName,
                                          callback: function ($$v) {
                                            _vm.bumenoptionsName = $$v
                                          },
                                          expression: "bumenoptionsName",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "原始接收人所属部门"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.finalFollowshow,
                                          expression: "screen.finalFollowshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("创建日期：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px !important",
                                        },
                                        attrs: {
                                          size: "small",
                                          type: "datetimerange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "  结束日期",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                          "value-format": "timestamp",
                                        },
                                        on: { change: _vm.FinalFollowUpEs },
                                        model: {
                                          value: _vm.share.finalFollow,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.share,
                                              "finalFollow",
                                              $$v
                                            )
                                          },
                                          expression: "share.finalFollow",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("创建日期")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.Sharingshow,
                                          expression: "screen.Sharingshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("共享日期：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px !important",
                                        },
                                        attrs: {
                                          size: "small",
                                          type: "datetimerange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "  结束日期",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                          "value-format": "timestamp",
                                        },
                                        on: { change: _vm.SharingDateClick },
                                        model: {
                                          value: _vm.share.SharingDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.share,
                                              "SharingDate",
                                              $$v
                                            )
                                          },
                                          expression: "share.SharingDate",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("共享日期")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comCreateshow,
                                          expression: "comCreateshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("创建人："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          ref: "clearValue",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.followMethod2,
                                            placeholder: "请选择创建人",
                                          },
                                          on: {
                                            change: _vm.followidEs,
                                            blur: _vm.SharerBlur2,
                                          },
                                          model: {
                                            value: _vm.share.follow_Id2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "follow_Id2",
                                                $$v
                                              )
                                            },
                                            expression: "share.follow_Id2",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.follow_Arr2,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("创建人")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comPlatshow,
                                          expression: "comPlatshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("推广平台："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            placeholder: "请搜索推广平台",
                                            size: "small",
                                          },
                                          model: {
                                            value: _vm.comPlat,
                                            callback: function ($$v) {
                                              _vm.comPlat = $$v
                                            },
                                            expression: "comPlat",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.comPlatClick },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("推广平台")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comNameshow,
                                          expression: "comNameshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("推广项目："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            placeholder: "请搜索推广项目",
                                            size: "small",
                                          },
                                          model: {
                                            value: _vm.comName,
                                            callback: function ($$v) {
                                              _vm.comName = $$v
                                            },
                                            expression: "comName",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.comNameClick },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("推广项目")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comWebsiteshow,
                                          expression: "comWebsiteshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("推广地址："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            placeholder: "请搜索推广地址",
                                            size: "small",
                                          },
                                          model: {
                                            value: _vm.comWebsite,
                                            callback: function ($$v) {
                                              _vm.comWebsite = $$v
                                            },
                                            expression: "comWebsite",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.comWebsiteClick },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("推广地址")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.nowDepartShow,
                                          expression: "screen.nowDepartShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("现跟进人所属部门：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        key: 88,
                                        ref: "nowDepartRef1",
                                        attrs: {
                                          options: _vm.share.bumenoptions,
                                          clearable: "",
                                          filterable: "",
                                          size: "small",
                                          placeholder: "请选择部门",
                                          "collapse-tags": "",
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                          },
                                        },
                                        on: {
                                          change: function (val) {
                                            return _vm.followMentNew(val, "1")
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "现跟进人所属部门"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.nowPersonShow,
                                          expression: "screen.nowPersonShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("现跟进人：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          ref: "clearValue",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.ReceiverMethod,
                                            placeholder: "请选择现跟进人",
                                          },
                                          on: {
                                            change: _vm.ReceiverInput,
                                            blur: _vm.ReceiverBlur,
                                          },
                                          model: {
                                            value: _vm.share.nowfollowid,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "nowfollowid",
                                                $$v
                                              )
                                            },
                                            expression: "share.nowfollowid",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.Receiver_Arr,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("现跟进人")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.studLevelShow,
                                          expression: "screen.studLevelShow",
                                        },
                                      ],
                                      staticStyle: {
                                        "margin-top": "15px",
                                        "line-height": "30px",
                                      },
                                    },
                                    [
                                      _c("student-level", {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        on: { studentLevel: _vm.studentLevel },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "现学员级别"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.dataBelongShow,
                                          expression: "screen.dataBelongShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("数据归属：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { "margin-right": "0" },
                                          attrs: {
                                            size: "small",
                                            filterable: "",
                                            placeholder: "请选择数据归属",
                                            clearable: "",
                                          },
                                          on: { change: _vm.comNameClick },
                                          model: {
                                            value: _vm.share.sea_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "sea_type",
                                                $$v
                                              )
                                            },
                                            expression: "share.sea_type",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            key: "item.value",
                                            attrs: { label: "全部", value: "" },
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.share.dataBelong,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.belongName,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("数据归属")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seeJudge
                        ? _c(
                            "el-main",
                            {
                              staticStyle: {
                                "padding-left": "0",
                                "padding-right": "0",
                              },
                            },
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.share.listLoading,
                                        expression: "share.listLoading",
                                      },
                                    ],
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.share.tableData,
                                      fit: "",
                                      border: "",
                                      id: "customerTable",
                                      "header-cell-style": {
                                        background: "#F8F9FB",
                                        color: "#222222",
                                      },
                                    },
                                    on: { "sort-change": _vm.sortEs },
                                  },
                                  [
                                    _c("el-table-column", {
                                      key: 1,
                                      attrs: {
                                        fixed: "",
                                        label: "客户名称",
                                        prop: "cname",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 2,
                                        attrs: {
                                          label: "学员来源",
                                          "min-width": "150",
                                          prop: "source_client_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "学员来源"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("学员来源")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.is_director == 1
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 3,
                                            attrs: {
                                              label: "共享人",
                                              "min-width": "150",
                                              prop: "share_name",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _vm.searchJudge
                                              ? _c(
                                                  "template",
                                                  { slot: "header" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                          color: "#1890ff",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.Activeshow(
                                                              "共享人"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("共享人")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.is_director == 0
                                      ? _c("el-table-column", {
                                          key: 3,
                                          attrs: {
                                            label: "共享人",
                                            "min-width": "150",
                                            prop: "share_name",
                                            align: "center",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 10,
                                        attrs: {
                                          label: "创建人",
                                          prop: "create_name",
                                          align: "center",
                                          width: "120px",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "创建人"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("创建人")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.is_director == 1
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 4,
                                            attrs: {
                                              label: "创建人所属部门",
                                              "min-width": "150",
                                              prop: "create_structure_name",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _vm.searchJudge
                                              ? _c(
                                                  "template",
                                                  { slot: "header" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                          color: "#1890ff",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.Activeshow(
                                                              "创建人所属部门"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("创建人所属部门")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.is_director == 0
                                      ? _c("el-table-column", {
                                          key: 4,
                                          attrs: {
                                            label: "创建人所属部门",
                                            "min-width": "150",
                                            prop: "create_structure_name",
                                            align: "center",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 5,
                                        attrs: {
                                          label: "原始数据接收人",
                                          "min-width": "150",
                                          prop: "receive_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "原始数据接收人"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("原始数据接收人")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 6,
                                        attrs: {
                                          label: "原始接收人所属部门",
                                          "min-width": "150",
                                          prop: "receive_structure_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "原始接收人所属部门"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("原始接收人所属部门")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 20,
                                        attrs: {
                                          label: "现跟进人",
                                          "min-width": "150",
                                          prop: "follower_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "现跟进人"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("现跟进人")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 21,
                                        attrs: {
                                          label: "现跟进人所属部门",
                                          "min-width": "150",
                                          prop: "follower_structure_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "现跟进人所属部门"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("现跟进人所属部门")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 22,
                                        attrs: {
                                          label: "现学员级别",
                                          "min-width": "150",
                                          prop: "customer_level_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "现学员级别"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("现学员级别")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 23,
                                        attrs: {
                                          label: "数据归属",
                                          "min-width": "150",
                                          prop: "sea_type",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.sea_type == 0
                                                    ? _c("span", [
                                                        _vm._v("私海"),
                                                      ])
                                                    : scope.row.sea_type == 1
                                                    ? _c("span", [
                                                        _vm._v("公海"),
                                                      ])
                                                    : scope.row.sea_type == 2
                                                    ? _c("span", [
                                                        _vm._v("二级公海"),
                                                      ])
                                                    : scope.row.sea_type == 3
                                                    ? _c("span", [
                                                        _vm._v("外送客户公海"),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          447648103
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "数据归属"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("数据归属")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.searchJudge
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 7,
                                            attrs: {
                                              label: "创建日期",
                                              "min-width": "150",
                                              sortable: "custom",
                                              prop: "create_time",
                                              align: "center",
                                              filters: [],
                                            },
                                          },
                                          [
                                            _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "创建日期"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("创建日期")]
                                              ),
                                            ]),
                                          ],
                                          2
                                        )
                                      : _c("el-table-column", {
                                          key: 7,
                                          attrs: {
                                            label: "创建日期",
                                            "min-width": "150",
                                            prop: "create_time",
                                            align: "center",
                                          },
                                        }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      key: 8,
                                      attrs: {
                                        label: "客户电话",
                                        prop: "mobile",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.searchJudge
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 9,
                                            attrs: {
                                              label: "共享日期",
                                              sortable: "custom",
                                              "min-width": "150",
                                              prop: "share_time",
                                              align: "center",
                                              filters: [],
                                            },
                                          },
                                          [
                                            _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "共享日期"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("共享日期")]
                                              ),
                                            ]),
                                          ],
                                          2
                                        )
                                      : _c("el-table-column", {
                                          key: 9,
                                          attrs: {
                                            label: "共享日期",
                                            "min-width": "150",
                                            prop: "share_time",
                                            align: "center",
                                          },
                                        }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      key: 24,
                                      attrs: {
                                        label: "首咨时间间隔",
                                        "min-width": "150",
                                        prop: "first_call_interval_time",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sumTimeFilter(
                                                        scope.row
                                                          .first_call_interval_time
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2559895924
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      key: 25,
                                      attrs: {
                                        label: "拨打次数",
                                        "min-width": "150",
                                        prop: "all_call_num",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 11,
                                        attrs: {
                                          label: "推广平台",
                                          prop: "platform_name",
                                          align: "center",
                                          width: "120px",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "推广平台"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("推广平台")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 12,
                                        attrs: {
                                          label: "推广项目",
                                          prop: "project_name",
                                          align: "center",
                                          width: "120px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.project_name
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2672470571
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "推广项目"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("推广项目")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 13,
                                        attrs: {
                                          label: "推广地址",
                                          prop: "communication_website",
                                          align: "center",
                                          width: "120px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row
                                                    .communication_website
                                                    .length > 30
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass:
                                                            "itemDes",
                                                          attrs: {
                                                            content:
                                                              scope.row
                                                                .communication_website,
                                                            "popper-class":
                                                              "toolp",
                                                            placement:
                                                              "top-start",
                                                            effect: "light",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row.communication_website.slice(
                                                                  0,
                                                                  30
                                                                ) + "..."
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .communication_website
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1042608129
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "推广地址"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("推广地址")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seeJudge
                        ? _c("el-pagination", {
                            staticClass: "pagination",
                            attrs: {
                              "current-page": _vm.share.page,
                              "page-sizes": [10, 20, 30, 50, 100],
                              "page-size": _vm.share.pagesize,
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.share.total,
                              background: "",
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                              "update:currentPage": function ($event) {
                                return _vm.$set(_vm.share, "page", $event)
                              },
                              "update:current-page": function ($event) {
                                return _vm.$set(_vm.share, "page", $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-tab-pane",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["已接收客户数据"],
                          expression: "['已接收客户数据']",
                        },
                      ],
                      attrs: { label: "已接收客户数据", name: "receive" },
                    },
                    [
                      _vm.searchJudge1
                        ? _c(
                            "el-row",
                            {
                              staticClass: "btn",
                              staticStyle: { "margin-right": "0" },
                              attrs: { gutter: 24 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: {
                                    "padding-right": "0",
                                    "padding-top": "20px",
                                    float: "right",
                                  },
                                  attrs: { span: 4 },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "input-with-select",
                                      attrs: {
                                        placeholder: "请搜索电话、名称",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.share.ClassSearch,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.share,
                                            "ClassSearch",
                                            $$v
                                          )
                                        },
                                        expression: "share.ClassSearch",
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          slot: "append",
                                          type: "primary",
                                          size: "small",
                                          icon: "el-icon-search",
                                        },
                                        on: { click: _vm.ClassSearchInput },
                                        slot: "append",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.searchJudge1
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 24 } },
                            [
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.sourceClientShow,
                                          expression: "screen.sourceClientShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("学员来源：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            width: "180px",
                                            "margin-right": "0",
                                          },
                                          attrs: {
                                            size: "small",
                                            filterable: "",
                                            multiple: "",
                                            "collapse-tags": "",
                                            placeholder: "请搜索学员来源",
                                            clearable: "",
                                          },
                                          on: { change: _vm.flowerArrayEs },
                                          model: {
                                            value: _vm.share.source_client_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "source_client_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "share.source_client_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.flower_Array,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.flower_name,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("学员来源")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.founderShow,
                                          expression: "screen.founderShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("共享人：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          ref: "clearValue",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.followMethod,
                                            placeholder: "请选择共享人",
                                          },
                                          on: {
                                            change: _vm.followidEs,
                                            blur: _vm.SharerBlur,
                                          },
                                          model: {
                                            value: _vm.share.follow_Id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "follow_Id",
                                                $$v
                                              )
                                            },
                                            expression: "share.follow_Id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.follow_Arr,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("共享人")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.founderMentShow,
                                          expression: "screen.founderMentShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("创建人所属部门：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        key: _vm.key3,
                                        attrs: {
                                          options: _vm.share.follow_Ments,
                                          clearable: "",
                                          filterable: "",
                                          size: "small",
                                          placeholder: "请选择部门",
                                          "collapse-tags": "",
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                          },
                                        },
                                        on: { change: _vm.followMent },
                                        model: {
                                          value: _vm.follow_Names,
                                          callback: function ($$v) {
                                            _vm.follow_Names = $$v
                                          },
                                          expression: "follow_Names",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "创建人所属部门"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.ReceivertShow,
                                          expression: "screen.ReceivertShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("数据接收人：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          ref: "clearValue",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.ReceiverMethod,
                                            placeholder: "请选择接收人",
                                          },
                                          on: {
                                            change: _vm.ReceiverInput,
                                            blur: _vm.ReceiverBlur,
                                          },
                                          model: {
                                            value: _vm.share.Receiver,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "Receiver",
                                                $$v
                                              )
                                            },
                                            expression: "share.Receiver",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.Receiver_Arr,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "原始数据接收人"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.ReceiverMentShow,
                                          expression: "screen.ReceiverMentShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("原始接收人所属部门：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        key: _vm.key4,
                                        attrs: {
                                          options: _vm.share.bumenoptionss,
                                          clearable: "",
                                          size: "small",
                                          placeholder: "请选择部门",
                                          "collapse-tags": "",
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                            children: "user_arr",
                                          },
                                        },
                                        on: { change: _vm.handleChange },
                                        model: {
                                          value: _vm.bumenoptionsNames,
                                          callback: function ($$v) {
                                            _vm.bumenoptionsNames = $$v
                                          },
                                          expression: "bumenoptionsNames",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "原始接收人所属部门"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.finalFollowshow,
                                          expression: "screen.finalFollowshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("创建日期：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px !important",
                                        },
                                        attrs: {
                                          size: "small",
                                          type: "datetimerange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "  结束日期",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                          "value-format": "timestamp",
                                        },
                                        on: { change: _vm.FinalFollowUpEs },
                                        model: {
                                          value: _vm.share.finalFollow,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.share,
                                              "finalFollow",
                                              $$v
                                            )
                                          },
                                          expression: "share.finalFollow",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("创建日期")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.Sharingshow,
                                          expression: "screen.Sharingshow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("共享日期：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-date-picker", {
                                        staticStyle: {
                                          width: "250px !important",
                                        },
                                        attrs: {
                                          size: "small",
                                          type: "datetimerange",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "  结束日期",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                          "value-format": "timestamp",
                                        },
                                        on: { change: _vm.SharingDateClick },
                                        model: {
                                          value: _vm.share.SharingDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.share,
                                              "SharingDate",
                                              $$v
                                            )
                                          },
                                          expression: "share.SharingDate",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("共享日期")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comCreateshow2,
                                          expression: "comCreateshow2",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("创建人："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.followMethod2,
                                            placeholder: "请选择创建人",
                                          },
                                          on: {
                                            change: _vm.followidEs,
                                            blur: _vm.SharerBlur2,
                                          },
                                          model: {
                                            value: _vm.share.follow_Id2,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "follow_Id2",
                                                $$v
                                              )
                                            },
                                            expression: "share.follow_Id2",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.follow_Arr2,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("创建人 ")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comPlatshow2,
                                          expression: "comPlatshow2",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("推广平台："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            placeholder: "请搜索推广平台",
                                            size: "small",
                                          },
                                          model: {
                                            value: _vm.comPlat,
                                            callback: function ($$v) {
                                              _vm.comPlat = $$v
                                            },
                                            expression: "comPlat",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.comPlatClick },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "推广平台 "
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comNameshow2,
                                          expression: "comNameshow2",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("推广项目："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            placeholder: "请搜索推广项目",
                                            size: "small",
                                          },
                                          model: {
                                            value: _vm.comName,
                                            callback: function ($$v) {
                                              _vm.comName = $$v
                                            },
                                            expression: "comName",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.comNameClick },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "推广项目 "
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.comWebsiteshow2,
                                          expression: "comWebsiteshow2",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c("span", { staticClass: "nameTitle" }, [
                                        _vm._v("推广地址："),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-input",
                                        {
                                          staticStyle: { width: "250px" },
                                          attrs: {
                                            placeholder: "请搜索推广地址",
                                            size: "small",
                                          },
                                          model: {
                                            value: _vm.comWebsite,
                                            callback: function ($$v) {
                                              _vm.comWebsite = $$v
                                            },
                                            expression: "comWebsite",
                                          },
                                        },
                                        [
                                          _c("el-button", {
                                            attrs: {
                                              slot: "append",
                                              type: "primary",
                                              size: "small",
                                              icon: "el-icon-search",
                                            },
                                            on: { click: _vm.comWebsiteClick },
                                            slot: "append",
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "推广地址 "
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.nowDepartShow,
                                          expression: "screen.nowDepartShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("现跟进人所属部门：")]
                                      ),
                                      _vm._v(" "),
                                      _c("el-cascader", {
                                        key: 88,
                                        ref: "nowDepartRef2",
                                        attrs: {
                                          options: _vm.share.bumenoptionss,
                                          clearable: "",
                                          filterable: "",
                                          size: "small",
                                          placeholder: "请选择部门",
                                          "collapse-tags": "",
                                          props: {
                                            value: "id",
                                            label: "name",
                                            multiple: true,
                                            children: "user_arr",
                                          },
                                        },
                                        on: {
                                          change: function (val) {
                                            return _vm.followMentNew(val, "2")
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "现跟进人所属部门"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.nowPersonShow,
                                          expression: "screen.nowPersonShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("现跟进人：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          ref: "clearValue",
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                          attrs: {
                                            filterable: "",
                                            remote: "",
                                            clearable: "",
                                            size: "small",
                                            "collapse-tags": "",
                                            "reserve-keyword": "",
                                            "remote-method": _vm.ReceiverMethod,
                                            placeholder: "请选择现跟进人",
                                          },
                                          on: {
                                            change: function (val) {
                                              return _vm.followMentNew(val, "3")
                                            },
                                            blur: _vm.ReceiverBlur,
                                          },
                                          model: {
                                            value: _vm.share.nowfollowidUser,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "nowfollowidUser",
                                                $$v
                                              )
                                            },
                                            expression: "share.nowfollowidUser",
                                          },
                                        },
                                        _vm._l(
                                          _vm.share.Receiver_Arr,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.realname,
                                                value: item.id,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("现跟进人")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.studLevelShow,
                                          expression: "screen.studLevelShow",
                                        },
                                      ],
                                      staticStyle: {
                                        "margin-top": "15px",
                                        "line-height": "30px",
                                      },
                                    },
                                    [
                                      _c("student-level", {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                        on: { studentLevel: _vm.studentLevel },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow(
                                                "现学员级别"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  attrs: {
                                    xs: 24,
                                    sm: 16,
                                    md: 13,
                                    lg: 11,
                                    xl: 11,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.screen.dataBelongShow,
                                          expression: "screen.dataBelongShow",
                                        },
                                      ],
                                      staticStyle: { "margin-top": "15px" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "nameTitle projectScreening",
                                        },
                                        [_vm._v("数据归属：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { "margin-right": "0" },
                                          attrs: {
                                            size: "small",
                                            filterable: "",
                                            placeholder: "请选择数据归属",
                                            clearable: "",
                                          },
                                          on: { change: _vm.comNameClick },
                                          model: {
                                            value: _vm.share.sea_type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.share,
                                                "sea_type",
                                                $$v
                                              )
                                            },
                                            expression: "share.sea_type",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            key: "item.value",
                                            attrs: { label: "全部", value: "" },
                                          }),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.share.dataBelong,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.belongName,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.Deletesshow("数据归属")
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seeJudge1
                        ? _c(
                            "el-main",
                            {
                              staticStyle: {
                                "padding-left": "0",
                                "padding-right": "0",
                              },
                            },
                            [
                              [
                                _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.share.listLoading,
                                        expression: "share.listLoading",
                                      },
                                    ],
                                    ref: "multipleTable",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.share.tableData,
                                      fit: "",
                                      border: "",
                                      id: "customerTable",
                                      "header-cell-style": {
                                        background: "#F8F9FB",
                                        color: "#222222",
                                      },
                                    },
                                    on: { "sort-change": _vm.sortEs },
                                  },
                                  [
                                    _c("el-table-column", {
                                      key: 1,
                                      attrs: {
                                        fixed: "",
                                        label: "客户名称",
                                        prop: "cname",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 2,
                                        attrs: {
                                          label: "学员来源",
                                          "min-width": "150",
                                          prop: "source_client_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge1
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "学员来源"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("学员来源")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 3,
                                        attrs: {
                                          label: "共享人",
                                          "min-width": "150",
                                          prop: "share_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge1
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "共享人"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("共享人")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 10,
                                        attrs: {
                                          label: "创建人",
                                          prop: "create_name",
                                          align: "center",
                                          width: "120px",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "创建人 "
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("创建人")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 4,
                                        attrs: {
                                          label: "创建人所属部门",
                                          "min-width": "150",
                                          prop: "create_structure_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge1
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "创建人所属部门"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("创建人所属部门")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.is_director == 1
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 5,
                                            attrs: {
                                              label: "原始数据接收人",
                                              "min-width": "150",
                                              prop: "receive_name",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _vm.searchJudge1
                                              ? _c(
                                                  "template",
                                                  { slot: "header" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                          color: "#1890ff",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.Activeshow(
                                                              "原始数据接收人"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("原始数据接收人")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.is_director == 0
                                      ? _c("el-table-column", {
                                          key: 5,
                                          attrs: {
                                            label: "原始数据接收人",
                                            "min-width": "150",
                                            prop: "receive_name",
                                            align: "center",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.is_director == 1
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 6,
                                            attrs: {
                                              label: "原始接收人所属部门",
                                              "min-width": "150",
                                              prop: "receive_structure_name",
                                              align: "center",
                                            },
                                          },
                                          [
                                            _vm.searchJudge1
                                              ? _c(
                                                  "template",
                                                  { slot: "header" },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                          color: "#1890ff",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.Activeshow(
                                                              "原始接收人所属部门"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "原始接收人所属部门"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.is_director == 0
                                      ? _c("el-table-column", {
                                          key: 6,
                                          attrs: {
                                            label: "原始接收人所属部门",
                                            "min-width": "150",
                                            prop: "receive_structure_name",
                                            align: "center",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 20,
                                        attrs: {
                                          label: "现跟进人",
                                          "min-width": "150",
                                          prop: "follower_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "现跟进人"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("现跟进人")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 21,
                                        attrs: {
                                          label: "现跟进人所属部门",
                                          "min-width": "150",
                                          prop: "follower_structure_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "现跟进人所属部门"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("现跟进人所属部门")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 22,
                                        attrs: {
                                          label: "现学员级别",
                                          "min-width": "150",
                                          prop: "customer_level_name",
                                          align: "center",
                                        },
                                      },
                                      [
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "现学员级别"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("现学员级别")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 23,
                                        attrs: {
                                          label: "数据归属",
                                          "min-width": "150",
                                          prop: "sea_type",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row.sea_type == 0
                                                    ? _c("span", [
                                                        _vm._v("私海"),
                                                      ])
                                                    : scope.row.sea_type == 1
                                                    ? _c("span", [
                                                        _vm._v("公海"),
                                                      ])
                                                    : scope.row.sea_type == 2
                                                    ? _c("span", [
                                                        _vm._v("二级公海"),
                                                      ])
                                                    : scope.row.sea_type == 3
                                                    ? _c("span", [
                                                        _vm._v("外送客户公海"),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          447648103
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _vm.searchJudge
                                          ? _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "数据归属"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("数据归属")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _vm.searchJudge1
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 7,
                                            attrs: {
                                              label: "创建日期",
                                              "min-width": "150",
                                              sortable: "custom",
                                              prop: "create_time",
                                              align: "center",
                                              filters: [],
                                            },
                                          },
                                          [
                                            _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "创建日期"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("创建日期")]
                                              ),
                                            ]),
                                          ],
                                          2
                                        )
                                      : _c("el-table-column", {
                                          key: 7,
                                          attrs: {
                                            label: "创建日期",
                                            "min-width": "150",
                                            prop: "create_time",
                                            align: "center",
                                          },
                                        }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      key: 8,
                                      attrs: {
                                        label: "客户电话",
                                        prop: "mobile",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.searchJudge1
                                      ? _c(
                                          "el-table-column",
                                          {
                                            key: 9,
                                            attrs: {
                                              label: "共享日期",
                                              sortable: "custom",
                                              "min-width": "150",
                                              prop: "share_time",
                                              align: "center",
                                              filters: [],
                                            },
                                          },
                                          [
                                            _c("template", { slot: "header" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                    color: "#1890ff",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.Activeshow(
                                                        "共享日期"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("共享日期")]
                                              ),
                                            ]),
                                          ],
                                          2
                                        )
                                      : _c("el-table-column", {
                                          key: 9,
                                          attrs: {
                                            label: "共享日期",
                                            "min-width": "150",
                                            prop: "share_time",
                                            align: "center",
                                          },
                                        }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      key: 24,
                                      attrs: {
                                        label: "首咨时间间隔",
                                        "min-width": "150",
                                        prop: "first_call_interval_time",
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.sumTimeFilter(
                                                        scope.row
                                                          .first_call_interval_time
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2559895924
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      key: 25,
                                      attrs: {
                                        label: "拨打次数",
                                        "min-width": "150",
                                        prop: "all_call_num",
                                        align: "center",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 11,
                                        attrs: {
                                          label: "推广平台",
                                          prop: "platform_name",
                                          align: "center",
                                          width: "120px",
                                        },
                                      },
                                      [
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "推广平台 "
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("推广平台")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 12,
                                        attrs: {
                                          label: "推广项目",
                                          prop: "project_name",
                                          align: "center",
                                          width: "120px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.project_name
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2672470571
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "推广项目 "
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("推广项目")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        key: 13,
                                        attrs: {
                                          label: "推广地址",
                                          prop: "communication_website",
                                          align: "center",
                                          width: "120px",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  scope.row
                                                    .communication_website
                                                    .length > 30
                                                    ? _c(
                                                        "el-tooltip",
                                                        {
                                                          staticClass:
                                                            "itemDes",
                                                          attrs: {
                                                            content:
                                                              scope.row
                                                                .communication_website,
                                                            "popper-class":
                                                              "toolp",
                                                            placement:
                                                              "top-start",
                                                            effect: "light",
                                                          },
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                scope.row.communication_website.slice(
                                                                  0,
                                                                  30
                                                                ) + "..."
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .communication_website
                                                          )
                                                        ),
                                                      ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1042608129
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c("template", { slot: "header" }, [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                color: "#1890ff",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.Activeshow(
                                                    "推广地址 "
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("推广地址")]
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.seeJudge1
                        ? _c("el-pagination", {
                            staticClass: "pagination",
                            attrs: {
                              "current-page": _vm.share.page,
                              "page-sizes": [10, 20, 30, 50, 100],
                              "page-size": _vm.share.pagesize,
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: _vm.share.total,
                              background: "",
                            },
                            on: {
                              "size-change": _vm.handleSizeChange,
                              "current-change": _vm.handleCurrentChange,
                              "update:currentPage": function ($event) {
                                return _vm.$set(_vm.share, "page", $event)
                              },
                              "update:current-page": function ($event) {
                                return _vm.$set(_vm.share, "page", $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "boxImg", staticStyle: { padding: "20px" } },
            [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
            1
          ),
      _vm._v(" "),
      _c("my-export-excel", {
        ref: "downloadExcelRef",
        staticStyle: { display: "none" },
        attrs: { dialogType: 0 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }